import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog-16-image001.png"
import image2 from "../../images/blog20-1.jpg"
import image3 from "../../images/blog20-2.png"

export default function CanIExerciseWithLowBackPain(){
    return(
        <BlogPostOld
            src={image}
            title={"Can I Exercise with Low Back Pain?"}
            summary={"If I was forced to answer this simply, a Stone Cold Steve Austin “Can I get a Hell Yeah?” would swiftly follow. Since we are here for me to elaborate and share my thoughts on the topic, I’ll go a little deeper. Sit back and enjoy while I passionately nerd out. "}
            date={"August 22, 2022"}
            author={"Dr. Donald Mull, DC"}
        ><>
            <p>
                If I was forced to answer this simply, a Stone Cold Steve Austin “Can I get
                a Hell Yeah?” would swiftly follow. Since we are here for me to elaborate
                and share my thoughts on the topic, I’ll go a little deeper. Sit back and
                enjoy while I passionately nerd out.&nbsp;
            </p>
            <p>
                Back pain is something that I have been lucky enough to have helped many of
                our clients bounce back from. Being a Chiropractor, this is the most common
                thing the public seeks my help for so I have spent a lot of time staying
                informed on the topic.&nbsp;
            </p>
            <p>
                When it comes to advice about low back pain, the misinformation that gets
                passed along is alarming and unfortunately doctors may be the number one
                culprit. Words are important here because words drive behavior. Too often
                are physicians creating a narrative that encourages a fragility mindset.
            </p>
            <p>
                I am not saying to just rub dirt on it and get back in the game. While that
                can work on a select few, there are plenty of people that could benefit from
                guidance that will help them get back in the game and do whatever it is they
                love to do with as little limitations as possible.
            </p>
            <p>
                From my perspective, once we have established there are no “red flags” that
                are detrimental to health and require urgent medical attention (fractures,
                cancerous lesions, cauda equina, etc) the name of the game is to encourage
                healthy lifestyle habits. The healthier a person is, the better that person
                can bounce back from an injury.&nbsp;
            </p>
            <p>
                That being said, physical activity is a staple of health and performance. We
                have decent guidelines that we can use on the amount of physical activity
                with prescribable doses. The World Health Organization (WHO) recommends 150
                minutes of moderate activity and/or 300 minutes of moderate activity and 2
                full body resistance training sessions a week (
                <a
                    href="https://kirehab.com/blog/why-working-out-is-good-for-you"
                    target="_blank"
                    rel="noopener"
                >
                    more on this topic on our physical activity blog
                </a>
                ).&nbsp;
            </p>
            <p>
                We have seen that the risk of having low back pain DECREASES by almost 20%
                with MORE physical activity.&nbsp;
            </p>
            <img src={image2} alt={""}/>
            <p>
                This also looked at other lifestyle factors like smoking that have an
                enormous negative effect on back pain. Take home for me there is, if we
                focus on positive lifestyle changes like resuming physical activity to meet
                baseline recommendations, eating healthy and consuming less harmful products
                we can then shift the odds in your favor for better outcomes.
            </p>
            <p>
                Though we know the cessation of smoking has MASSIVE benefits on health, it
                may be more realistic for us to help people become more physically
                active.&nbsp;
            </p>
            <img src={image3} alt={""}/>
            <p>
                This is even more true if you have experienced symptoms for a long time.
                There is a large body of evidence that points to exercise not only being
                acceptable but recommended as a treatment for chronic low back pain.
                Recently a{" "}
                <a
                    href="https://pubmed.ncbi.nlm.nih.gov/35914641/"
                    target="_blank"
                    rel="noopener"
                >
                    review of literature
                </a>{" "}
                with over 10,000 total participants showed that an individualized exercise
                program worked best to help with symptoms over a 12 week period.&nbsp;
            </p>
            <p>
                There are a lot of ways you can get back to being physically active and if
                your doctor is not providing a positive outlook, it may be time to find a
                new physician. The evidence is stacked in your favor. You CAN become
                stronger and more resilient.&nbsp;
            </p>
            <p>
                We have created a resource for those new to exercise and would like to get
                moving again. Our{" "}
                <a
                    href="https://kirehab.com/low-back-program"
                    target="_blank"
                    rel="noopener"
                >
                    conquering low back pain e-book
                </a>{" "}
                was made just for you to get kick-started back to being physically active.
                Pair this with tolerable aerobic exercise like walking or biking to get your
                started.&nbsp;
            </p>
            <p>
                We also have a bunch of information on our{" "}
                <a
                    href="https://www.instagram.com/kineticimpact/"
                    target="_blank"
                    rel="noopener"
                >
                    instagram page
                </a>{" "}
                and{" "}
                <a
                    href="https://www.youtube.com/channel/UCL6l4P7Pi2HIaVGb2xPi8oA"
                    target="_blank"
                    rel="noopener"
                >
                    youtube channel
                </a>{" "}
                that you can follow. If you have any questions don’t hesitate to contact
                us.&nbsp;
            </p>
            <p>
                <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4934575/#:~:text=Aerobic%20exercise%20can%20benefit%20CLBP,in%20back%20pain%20%5B55%5D."
                    target="_blank"
                    rel="noopener"
                >
                    Gordon R, Bloxham S. A Systematic Review of the Effects of Exercise and
                    Physical Activity on Non-Specific Chronic Low Back Pain. Healthcare
                    (Basel). 2016 Apr 25;4(2):22. doi: 10.3390/healthcare4020022. PMID:
                    27417610; PMCID: PMC4934575.
                </a>
            </p>
            <p>
                <a
                    href="https://pubmed.ncbi.nlm.nih.gov/28436583/"
                    target="_blank"
                    rel="noopener"
                >
                    Geneen LJ, Moore RA, Clarke C, Martin D, Colvin LA, Smith BH. Physical
                    activity and exercise for chronic pain in adults: an overview of Cochrane
                    Reviews. Cochrane Database Syst Rev. 2017 Apr 24;4(4):CD011279. doi:
                    10.1002/14651858.CD011279.pub3. PMID: 28436583; PMCID: PMC5461882.
                </a>
            </p>
            <p>
                <a
                    href="https://pubmed.ncbi.nlm.nih.gov/28436583/"
                    target="_blank"
                    rel="noopener"
                >
                    Thornton JS, Frémont P, Khan K, Poirier P, Fowles J, Wells GD, Frankovich
                    RJ. Physical activity prescription: a critical opportunity to address a
                    modifiable risk factor for the prevention and management of chronic
                    disease: a position statement by the Canadian Academy of Sport and
                    Exercise Medicine. Br J Sports Med. 2016 Sep;50(18):1109-14. doi:
                    10.1136/bjsports-2016-096291. Epub 2016 Jun 22. PMID: 27335208.
                </a>
            </p>
            <p>
                <a
                    href="https://pubmed.ncbi.nlm.nih.gov/26483443/"
                    target="_blank"
                    rel="noopener"
                >
                    Gates AB. Making every contact count for physical activity--for tomorrow's
                    patients: the launch of the interdisciplinary, undergraduate, resources on
                    exercise medicine and health in the U.K. Br J Sports Med. 2016
                    Mar;50(6):322-3. doi: 10.1136/bjsports-2015-095489. Epub 2015 Oct 19.
                    PMID: 26483443.
                </a>
            </p>
            <p>
                <a
                    href="https://pubmed.ncbi.nlm.nih.gov/35914641/"
                    target="_blank"
                    rel="noopener"
                >
                    Fleckenstein J, Flössel P, Engel T, Krempel L, Stoll J, Behrens M,
                    Niederer D. Individualized exercise in chronic non-specific low back pain:
                    a systematic review with meta-analysis on the effects of exercise alone or
                    in combination with psychological interventions on pain and disability. J
                    Pain. 2022 Jul 29:S1526-5900(22)00364-9. doi: 10.1016/j.jpain.2022.07.005.
                    Epub ahead of print. PMID: 35914641.
                </a>
            </p>
        </>
        </BlogPostOld>

    )
}